export const valuesData = [
  {
    id: 1,
    title: 'assistance',
    icon: {
      dark: '/assets/icons/values/assistanceIconDarkBg.svg',
      light: '/assets/icons/values/assistanceIconLightBg.svg',
    },
    coordonates: {
      x: 15,
      y: -15,
    },
    animation: 'animateAssistance',
    rotate: {
      second: 15,
      third: -25,
    },
  },
  {
    id: 2,
    title: 'quality',
    icon: {
      dark: '/assets/icons/values/qualityIconDarkBg.svg',
      light: '/assets/icons/values/qualityIconLightBg.svg',
    },
    coordonates: {
      x: 75,
      y: -75,
    },
    animation: 'animateQuality',
    rotate: {
      second: -45,
      third: -85,
    },
  },
  {
    id: 3,
    title: 'support',
    icon: {
      dark: '/assets/icons/values/supportIconDarkBg.svg',
      light: '/assets/icons/values/supportIconLightBg.svg',
    },
    coordonates: {
      x: 135,
      y: -135,
    },
    animation: 'animateSupport',
    rotate: {
      second: -105,
      third: -145,
    },
  },
  {
    id: 4,
    title: 'relationship',
    icon: {
      dark: '/assets/icons/values/relationshipIconDarkBg.svg',
      light: '/assets/icons/values/relationshipIconLightBg.svg',
    },
    coordonates: {
      x: 195,
      y: -195,
    },
    animation: 'animateRelationship',
    rotate: {
      second: -165,
      third: -205,
    },
  },
  {
    id: 5,
    title: 'guidance',
    icon: {
      dark: '/assets/icons/values/guidanceIconDarkBg.svg',
      light: '/assets/icons/values/guidanceIconLightBg.svg',
    },
    coordonates: {
      x: 255,
      y: -255,
    },
    animation: 'animateGuidance',
    rotate: {
      second: -225,
      third: -265,
    },
  },
  {
    id: 6,
    title: 'solution',
    icon: {
      dark: '/assets/icons/values/solutionIconDarkBg.svg',
      light: '/assets/icons/values/solutionIconLightBg.svg',
    },
    coordonates: {
      x: 315,
      y: -315,
    },
    animation: 'animateSolution',
    rotate: {
      second: -284,
      third: -325,
    },
  },
];
