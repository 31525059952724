import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../AppContext';

export default function BrowserHistory() {
  const { pathname } = useLocation();
  const { browserHistoryObj } = useContext(AppContext);
  const [browserHistory, setBrowserHistory] = browserHistoryObj;

  useEffect(() => {
    const updateHistory = [...browserHistory, pathname];
    setBrowserHistory(updateHistory);
  }, [pathname]);

  return null;
}
