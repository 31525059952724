import React from 'react';
import './Spinner.component.scss';

export default function Spinner() {
  return (
    <div className="spinner">
        <div className="spinner-socket">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
        </div>
    </div>
  )
}
