export const portfolioData = [
  {
    id: 1,
    title: 'Managed IT',
    paragraph:
      '<p>Not just another Miami help desk, we are local IT consultants providing expertise in cybersecurity, audio visual, WiFi systems, cloud services, VoIP communications and more. \n <h1>What is managed IT?</h1></p>',
    icon: '/assets/icons/services/iconManagedIT.svg',
    link: 'services/managed-it',
    image: '/assets/images/services/managed-it.webp',
    markdown: 'managed-it.md',
    meta: 'Miami help desk, your virtual IT staff that remotely manages client computers',
  },
  {
    id: 2,
    title: 'CCTV/IP Surveillance Systems',
    paragraph:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant. Massa sapien faucibus et molestie ac feugiat sed. Vitae congue eu consequat ac felis donec et. Scelerisque viverra mauris in aliquam sem. Ullamcorper sit amet risus nullam eget felis eget. Hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper.',
    icon: '/assets/icons/services/iconSurveillanceSystems.svg',
    link: 'services/surveillance-systems',
    image: '/assets/images/services/surveillance-systems.webp',
    markdown: 'surveillance-systems.md',
    meta: 'We provide installation & support of security cameras for business, residential, and other organizations',
  },
  {
    id: 3,
    title: 'Audio/Visual',
    paragraph:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum facilisis. Tincidunt praesent semper feugiat nibh sed pulvinar proin. Varius quam quisque id diam vel quam elementum. Fermentum dui faucibus in ornare quam viverra orci. Ornare arcu dui vivamus arcu. Eget est lorem ipsum dolor sit amet consectetur adipiscing elit.',
    icon: '/assets/icons/services/iconAudioVisual.svg',
    link: 'services/audio-visual',
    image: '/assets/images/services/audio-visual.webp',
    markdown: 'audio-visual.md',
    meta: 'Miami home entertainment center, install and setup smartboards in classrooms, lobby digital signage or interactive digital video walls',
  },
  {
    id: 4,
    title: 'Cloud Services',
    paragraph:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dolor sit amet consectetur adipiscing elit ut aliquam. Blandit turpis cursus in hac habitasse platea. Tortor vitae purus faucibus ornare. Vitae auctor eu augue ut lectus arcu. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim. Aliquet risus feugiat in ante metus dictum at tempor commodo.',
    icon: '/assets/icons/services/iconCloudServices.svg',
    link: 'services/cloud-services',
    image: '/assets/images/services/cloud-services.webp',
    markdown: 'cloud-services.md',
    meta: 'Is your computer data being backed up? Don’t lose you precious photos and documents, be ready when disaster strikes',
  },
  {
    id: 5,
    title: 'Cybersecurity',
    paragraph:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Magna eget est lorem ipsum. Ut enim blandit volutpat maecenas volutpat. Nulla facilisi morbi tempus iaculis. Ut faucibus pulvinar elementum integer enim neque volutpat. Molestie at elementum eu facilisis sed odio morbi quis. Faucibus interdum posuere lorem ipsum dolor sit amet consectetur adipiscing. Amet mattis vulputate enim nulla.',
    icon: '/assets/icons/services/iconCybersecurity.svg',
    link: 'services/cybersecurity',
    image: '/assets/images/services/cybersecurity.webp',
    markdown: 'cybersecurity.md',
    meta: 'Local ISR technicians can assist with the installation and setup of your firewalls, routers and antivirus software',
  },
  {
    id: 6,
    title: 'VOIP',
    paragraph:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus feugiat in ante metus dictum at tempor. Habitant morbi tristique senectus et netus et. Eu tincidunt tortor aliquam nulla facilisi cras. Bibendum neque egestas congue quisque egestas. Egestas dui id ornare arcu odio ut sem.',
    icon: '/assets/icons/services//iconVOIP.svg',
    link: 'services/voip',
    image: '/assets/images/services/voip.webp',
    markdown: 'voip.md',
    meta: 'Affordable & customizable VoIP phone system. Simultaneous ring, video calls, record calls and more',
  },
  {
    id: 7,
    title: 'Networking',
    paragraph:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Maecenas sed enim ut sem viverra aliquet eget sit. Cursus eget nunc scelerisque viverra. Non tellus orci ac auctor augue mauris. Vitae auctor eu augue ut lectus arcu bibendum at. Cursus turpis massa tincidunt dui ut ornare lectus sit amet. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt vitae. Donec adipiscing tristique risus nec feugiat in fermentum posuere.',
    icon: '/assets/icons/services/iconNetworking.svg',
    link: 'services/networking',
    image: '/assets/images/services/networking.webp',
    markdown: 'networking.md',
    meta: 'Computer network installation and support of printers, routers, firewalls, access points, switches',
  },
  {
    id: 8,
    title: 'Smart Home Automation',
    paragraph:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Maecenas sed enim ut sem viverra aliquet eget sit. Cursus eget nunc scelerisque viverra. Non tellus orci ac auctor augue mauris. Vitae auctor eu augue ut lectus arcu bibendum at. Cursus turpis massa tincidunt dui ut ornare lectus sit amet. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt vitae. Donec adipiscing tristique risus nec feugiat in fermentum posuere.',
    icon: '/assets/icons/services/iconSmarthome.svg',
    link: 'services/smart-home-automation',
    image: '/assets/images/services/smart-home-automation.webp',
    markdown: 'smart-home-automation.md',
    meta: 'Control everything from a smart phone or tablet: security cameras, thermostat, lighting, entertainment system  or any other devices',
  },
  {
    id: 9,
    title: 'UX/UI & Web  Development',
    paragraph:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Maecenas sed enim ut sem viverra aliquet eget sit. Cursus eget nunc scelerisque viverra. Non tellus orci ac auctor augue mauris. Vitae auctor eu augue ut lectus arcu bibendum at. Cursus turpis massa tincidunt dui ut ornare lectus sit amet. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt vitae. Donec adipiscing tristique risus nec feugiat in fermentum posuere.',
    icon: '/assets/icons/services/iconWeb.svg',
    link: 'services/web-apps',
    image: '/assets/images/services/web.webp',
    markdown: 'web.md',
    meta: 'UX/UI design and fullstack web applications. Custom e-commerce sites. Digitize your business!',
  },
];
