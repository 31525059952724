import React, { useContext } from 'react';
import './ToggleSwitch.component.scss';

import { AppContext } from '../../AppContext';

export default function ToggleSwitch(props) {
  const { isLG, themeColorObj, toggleSwitcherObj, mobileMenuOpenObj } =
    useContext(AppContext);
  const themeColor = themeColorObj[0];
  const [isToggled, setIsToggled] = toggleSwitcherObj;
  const setMobileMenuOpen = mobileMenuOpenObj[1];

  const onToggle = () => {
    setIsToggled(!isToggled);
    !isLG && props.closeMenu();
    setMobileMenuOpen(false);
  };

  return (
    <div className="switcher">
      <div className="theme-mode">
        {themeColor === 'light' ? (
          <p className="navlinks">Go Dark</p>
        ) : (
          <p className="navlinks">Go Light</p>
        )}
      </div>
      <label className="toggle-switch">
        <input type="checkbox" checked={isToggled} onChange={onToggle} />
        <span className="switch" />
      </label>
    </div>
  );
}
