export const certificationsData = [
  {
    id: '1',
    link: '/assets/images/certifications/image01.webp',
  },
  {
    id: '2',
    link: '/assets/images/certifications/image02.webp',
  },
  {
    id: '3',
    link: '/assets/images/certifications/image03.webp',
  },
  {
    id: '4',
    link: '/assets/images/certifications/image04.webp',
  },
  {
    id: '5',
    link: '/assets/images/certifications/image05.webp',
  },
  {
    id: '6',
    link: '/assets/images/certifications/image06.webp',
  },
  {
    id: '7',
    link: '/assets/images/certifications/image07.webp',
  },
  {
    id: '8',
    link: '/assets/images/certifications/image08.webp',
  },
  {
    id: '9',
    link: '/assets/images/certifications/image09.webp',
  },
];
