import React, { useEffect, useContext } from 'react';
import './Menu.component.scss';
import variables from '../../styles/_variables.module.scss';
import { motion, useAnimation } from 'framer-motion';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch.component.jsx';
import { Link, useMatch } from 'react-router-dom';
import { AppContext } from '../../AppContext';

export default function Menu(props) {
  const { themeColorObj, isMenuOpenObj, mobileMenuOpenObj, isLG } =
    useContext(AppContext);
  const themeColor = themeColorObj[0];
  const isMenuOpen = isMenuOpenObj[0];
  const setMobileMenuOpen = mobileMenuOpenObj[1];
  const match = useMatch(`/`);

  const animateMenu = useAnimation();

  const handleJump = (e) => {
    setTimeout(() => {
      const element = document.getElementById(e);
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 300);
  };

  const closeMenu = () => {
    props.handleOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (!isLG) {
      animateMenu.set({
        x: '100vw',
      });
      animateMenu.start({
        x: 0,
      });
    }
  }, [isLG, animateMenu]);

  return (
    <motion.div
      className="menu"
      style={
        themeColor === 'light' && isLG && match
          ? { color: variables.primaryColor }
          : { color: variables.secondaryColor }
      }
      animate={animateMenu}
    >
      <ul>
        <li>
          <ToggleSwitch closeMenu={closeMenu} />
        </li>
        <li>
          <Link
            to="/"
            onClick={() => {
              handleJump('values');
              !isLG && closeMenu();
              setMobileMenuOpen(false);
            }}
            animate={{ isMenuOpen } ? { y: 0 } : { y: '-10' }}
          >
            <p className="navlinks">home</p>
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            onClick={() => {
              // handleJump('carousel');
              !isLG && closeMenu();
              setMobileMenuOpen(false);
            }}
          >
            <p className="navlinks">about</p>
          </Link>
        </li>
        <li>
          <Link
            to="/"
            onClick={() => {
              handleJump('services');
              !isLG && closeMenu();
              setMobileMenuOpen(false);
            }}
          >
            <p className="navlinks">services</p>
          </Link>
        </li>
        <li>
          <Link
            to="/"
            onClick={() => {
              handleJump('partners');
              !isLG && closeMenu();
              setMobileMenuOpen(false);
            }}
          >
            <p className="navlinks">partners</p>
          </Link>
        </li>
        <li>
          <Link
            to="/"
            onClick={() => {
              handleJump('contact');
              !isLG && closeMenu();
              setMobileMenuOpen(false);
            }}
          >
            <p className="navlinks">contact</p>
          </Link>
        </li>
      </ul>
    </motion.div>
  );
}
