import React, { useState, createContext, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { images } from './data/AboutCarouselImages.js';
import { portfolioData } from './data/PortofolioPageData.js';
import { valuesData } from './data/ValuesData.js';
import { certificationsData } from './data/CertificationsData..js';

export const AppContext = createContext();

export const AppProvider = (props) => {
  const layoutRef = useRef();
  const isXS = useMediaQuery({ query: `(min-width: 0px)` });
  const isSM = useMediaQuery({ query: `(min-width: 576px)` });
  const isMD = useMediaQuery({ query: `(min-width: 768px)` });
  const isLG = useMediaQuery({ query: `(min-width: 992px)` });
  const isXL = useMediaQuery({ query: `(min-width: 1200px)` });
  const isXXL = useMediaQuery({ query: `(min-width: 1400px)` });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });

  const [themeColor, setThemeColor] = useState('light');
  const [isToggled, setIsToggled] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(null);
  const [browserHistory, setBrowserHistory] = useState('/');

  const [imageIndex, setImageIndex] = useState(0);

  const [portofolioPageId, setPortofolioPageId] = useState(null);
  const [portfolioPageTitle, setPortfolioPageTitle] = useState('');
  const [portfolioPageIcon, setPortfolioPageIcon] = useState('');
  const [portfolioPageParagraph, setPortfolioPageParagraph] = useState('');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const changePortofolioPageId = (event) => {
    setPortofolioPageId(event);
  };

  return (
    <AppContext.Provider
      value={{
        browserHistoryObj: [browserHistory, setBrowserHistory],
        portofolioPageIdObj: [portofolioPageId, setPortofolioPageId],
        changePortofolioPageId,
        themeColorObj: [themeColor, setThemeColor],
        toggleSwitcherObj: [isToggled, setIsToggled],
        isMenuOpenObj: [isMenuOpen, setIsMenuOpen],
        mobileMenuOpenObj: [mobileMenuOpen, setMobileMenuOpen],
        images,
        imageIndexObj: [imageIndex, setImageIndex],
        certificationsData,
        portfolioData,
        valuesData,
        layoutRef,
        isXS,
        isSM,
        isMD,
        isLG,
        isXL,
        isXXL,
        isPortrait,
        isLandscape,
        portfolioPageTitleObj: [portfolioPageTitle, setPortfolioPageTitle],
        portfolioPageIconObj: [portfolioPageIcon, setPortfolioPageIcon],
        portfolioPageParagraphObj: [
          portfolioPageParagraph,
          setPortfolioPageParagraph,
        ],
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
