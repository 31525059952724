import React, { useEffect, useContext } from 'react';
import './Navbar.component.scss';
import Logo from '../Logo/Logo.component.jsx';
import Menu from '../Menu/Menu.component.jsx';
import { Link, useMatch } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import NavbarBehaviour from './Navbar.container';

import logoSecondaryColorFull from '../../assets/logos/logoSecondaryColorFull.svg';
import logoPrimaryColorFull from '../../assets/logos/logoPrimaryColorFull.svg';
import menuIconPrimaryColor from '../../assets/icons/menuIconPrimaryColor.svg';
import menuIconSecondary from '../../assets/icons/menuIconSecondaryColor.svg';

export default function Navbar() {
  const { themeColorObj, isLG, isXL, isMenuOpenObj, mobileMenuOpenObj } =
    useContext(AppContext);
  const themeColor = themeColorObj[0];
  const [isMenuOpen, setIsMenuOpen] = isMenuOpenObj;
  const [mobileMenuOpen, setMobileMenuOpen] = mobileMenuOpenObj;
  const match = useMatch(`/`);

  useEffect(() => {
    isLG ? setIsMenuOpen(true) : setIsMenuOpen(false);
  }, [isLG, setIsMenuOpen]);

  const handleOpen = () => {
    setIsMenuOpen();
  };

  NavbarBehaviour();

  let menu;

  if (isMenuOpen) {
    menu = <Menu handleOpen={handleOpen} />;
  }

  return (
    <nav className="navbar" id="navbar">
      <div className="navbar-content">
        <Link to="/">
          <Logo
            logo={
              !mobileMenuOpen && themeColor === 'light' && match
                ? logoPrimaryColorFull
                : logoSecondaryColorFull
            }
            width={'auto'}
            height={isXL ? '48px' : '40px'}
            class={'logo-isr'}
          />
        </Link>
        <button
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
            setMobileMenuOpen(!mobileMenuOpen);
          }}
          className="toggle-menu-icon"
          style={isLG ? { display: 'none' } : { display: 'flex' }}
        >
          <img
            src={
              !mobileMenuOpen && themeColor === 'light' && match
                ? menuIconPrimaryColor
                : menuIconSecondary
            }
            alt="toggle menu icon"
          />
        </button>
        {menu}
      </div>
    </nav>
  );
}
