export const images = [
  {
    id: '1',
    link: '/assets/images/work/IMG_0735.webp',
    service: 'smart home automation',
  },
  {
    id: '2',
    link: '/assets/images/work/IMG_0738.webp',
    service: 'managed it',
  },
  {
    id: '3',
    link: '/assets/images/work/IMG_1540.webp',
    service: 'cctv/ip survailance systems',
  },
  {
    id: '4',
    link: '/assets/images/work/IMG_1541.webp',
    service: 'audio visual',
  },
  {
    id: '5',
    link: '/assets/images/work/IMG_1831.webp',
    service: 'managed it',
  },
  {
    id: '6',
    link: '/assets/images/work/IMG_1833.webp',
    service: 'audio visual',
  },
  {
    id: '7',
    link: '/assets/images/work/IMG_2034.webp',
    service: 'cybersecurity',
  },
  {
    id: '8',
    link: '/assets/images/work/IMG_9950.webp',
    service: 'managed it',
  },
  {
    id: '9',
    link: '/assets/images/work/IMG_9952.webp',
    service: 'audio visual',
  },
];
