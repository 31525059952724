import { useContext, useEffect } from 'react';
import './Navbar.component.scss';
import { AppContext } from '../../AppContext';
import { useLocation } from 'react-router-dom';

export default function NavbarBehaviour() {
  const { themeColorObj, isXL } = useContext(AppContext);
  const themeColor = themeColorObj[0];
  let location = useLocation();
  const pathname = location.pathname;
  
  useEffect(() => {
    const navbar = document.getElementById('navbar');
    const app = document.getElementById('app');
    const vh = app.offsetHeight;

    let lastScrollTop = 0;

    app.addEventListener(
      'scroll',
      () => {
        const position = app.scrollTop;

        if(position > lastScrollTop) {
          navbar.classList.add('hide-navbar');
        } else {
          navbar.classList.remove('hide-navbar');
        }

        if (position > vh && themeColor === 'dark') {
          navbar.classList.add('bg-gradient-dark');
        } else {
          navbar.classList.remove('bg-gradient-dark');
        }

        if (position > vh && themeColor === 'light') {
          navbar.classList.add('bg-gradient-light');
        } else {
          navbar.classList.remove('bg-gradient-light');
        }

        if (position < vh && pathname === '/' && themeColor === 'dark') {
          navbar.classList.add('bg-gradient-dark');
        } else if (position < vh && pathname === '/' && themeColor === 'light') {
          navbar.classList.add('bg-gradient-light');
        }

        if(position < 250){
          navbar.classList.remove('bg-gradient-dark');
          navbar.classList.remove('bg-gradient-light');
        } else if (position < vh && isXL) {
          navbar.classList.remove('bg-gradient-dark');
          navbar.classList.remove('bg-gradient-light');
        }

        lastScrollTop = position <= 0 ? 0 : position;

      },
      { passive: true },
    );
  });
  return;
}