import React, { Suspense, lazy } from 'react';
import { useEffect, useContext } from 'react';
import './App.scss';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';
import { AppContext } from './AppContext';
import { handleViewportHeight } from './utils.js';
import Navbar from './components/Navbar/Navbar.component.jsx';
import Spinner from './components/Spinner/Spinner.component.jsx';

const HomePage = lazy(() => import('./pages/Home/HomePage.component.jsx'));
const PortfolioItemPage = lazy(() =>
  import('./pages/PortfolioItem/PortfolioItem.component.jsx'),
);
const AboutPage = lazy(() => import('./pages/About/AboutPage.component.jsx'));

// handleViewportHeight();

function App() {
  const { themeColorObj, toggleSwitcherObj } = useContext(AppContext);
  const [themeColor, setThemeColor] = themeColorObj;
  const [isToggled, setIsToggled] = toggleSwitcherObj;

  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        e.matches ? setIsToggled(true) : setIsToggled(false);
      });
    if (localStorage.getItem('theme')) {
      if (localStorage.getItem('theme') === 'dark') {
        setIsToggled(true);
      }
    } else if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      setIsToggled(true);
    } else {
      setIsToggled(false);
    }
  }, [setIsToggled, themeColor]);

  useEffect(() => {
    if (isToggled) {
      setThemeColor('dark');
      localStorage.setItem('theme', 'dark');
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      setThemeColor('light');
      localStorage.setItem('theme', 'light');
      document.documentElement.setAttribute('data-theme', 'light');
    }
  }, [isToggled, setThemeColor]);

  return (
    <div className="App" data-theme={themeColor} id="app">
      <Helmet>
        <title>ISR Technology</title>
        <meta
          name="description"
          content="ISR Technology - USA, MIAMI, FL. Our services: managed IT, wireless systems, networking, audio/visual, cybersecurity, VOIP, cloud services, security."
        />
      </Helmet>

      <Navbar />
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services/managed-it" element={<PortfolioItemPage />} />
          <Route
            path="/services/surveillance-systems"
            element={<PortfolioItemPage />}
          />
          <Route
            path="/services/audio-visual"
            element={<PortfolioItemPage />}
          />
          <Route
            path="/services/wireless-systems"
            element={<PortfolioItemPage />}
          />
          <Route
            path="/services/cloud-services"
            element={<PortfolioItemPage />}
          />
          <Route
            path="/services/cybersecurity"
            element={<PortfolioItemPage />}
          />
          <Route path="/services/voip" element={<PortfolioItemPage />} />
          <Route path="/services/networking" element={<PortfolioItemPage />} />
          <Route path="/services/web-apps" element={<PortfolioItemPage />} />
          <Route
            path="/services/smart-home-automation"
            element={<PortfolioItemPage />}
          />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
